import {
	json,
	type MetaFunction,
	type LoaderFunctionArgs, redirect,
} from '@remix-run/node'
import FindVehicle from '#app/components/find-vehicle.tsx'
import { ProviderConnectionForm } from '#app/utils/connections.tsx'
import {
	get_years,
	get_makes,
	get_models,
	get_submodels,
	get_engines,
	get_vehicles,
} from '#app/utils/partstech/partsTech.server.ts'
import { useOptionalUser } from '#app/utils/user.ts'
import { get_vehicle_data_from_query_string } from '#app/utils/vehicle.ts'
import Stepper from '#app/components/stepper.tsx'
// import { getLoggedInAnonymousUser } from '#app/utils/auth.server.ts';

interface Option {
	value: string
	label: string
}

async function get_vehicle_options(year, make, model, submodel) {
	let yearOptions: Option[] = []
	let makeOptions: Option[] = []
	let modelOptions: Option[] = []
	let submodelOptions: Option[] = []
	let engineOptions: Option[] = []

	const yearsList = await get_years()
	yearOptions = yearsList.map((year) => ({ value: year, label: String(year) }))

	const makesList = await get_makes()
	makeOptions = makesList.map((make) => ({
		value: make.makeId,
		label: make.makeName,
	}))
	if (year) {
		const makesList = await get_makes(year)
		makeOptions = makesList.map((make) => ({
			value: make.makeId,
			label: make.makeName,
		}))
	}

	if (year && make) {
		const modelsList = await get_models(year, make)
		modelOptions = modelsList.map((model) => ({
			value: model.modelId,
			label: model.modelName,
		}))
	}

	if (year && make && model) {
		const submodelsList = await get_submodels(year, make, model)
		submodelOptions = submodelsList.map((submodel) => ({
			value: submodel.submodelId,
			label: submodel.submodelName,
		}))
	}

	if (year && make && model && submodel) {
		const enginesList = await get_engines(year, make, model, submodel)
		engineOptions = enginesList.map((engine) => ({
			value: engine.engineId,
			label: engine.engineName,
		}))
	}

	return {
		yearOptions,
		makeOptions,
		modelOptions,
		submodelOptions,
		engineOptions,
	}
}

export const loader = async ({ request }: LoaderFunctionArgs) => {
	const { year, make, model, submodel, engine } =
		get_vehicle_data_from_query_string(request.url)
	const {
		yearOptions,
		makeOptions,
		modelOptions,
		submodelOptions,
		engineOptions,
	} = await get_vehicle_options(year, make, model, submodel)

	var parts = []
	const query = new URL(request.url).searchParams
	const keyword = query.get('keyword')

	let vehicleId = null
	if (year && make && model && submodel && engine) {
		const vehicles = await get_vehicles(year, make, model, submodel, engine)
		vehicleId = vehicles[0].vehicleId
	}

  if (year && make && model && submodel && engine && keyword) {
	return redirect(`/find-parts?keyword=${keyword}&vehicleID=${vehicleId}`);
  }

	// const { user, session } = await getLoggedInAnonymousUser(request);

	return json({
		year,
		make,
		model,
		submodel,
		engine,
		yearOptions,
		makeOptions,
		modelOptions,
		submodelOptions,
		engineOptions,
		vehicleId,
		keyword,
		// { headers: {
		//   'set-cookie': await authSessionStorage.commitSession(
		//     authSession, { expires: session.expirationDate : undefined })
		//  }
		// )}
	})
}

export default function Index() {
	return (
		<main>
			<div className="mx-auto max-w-[768px]">
				<div
					style={{
						backgroundImage:
							'url(/img/8ce62651-a9ec-4f09-a327-6a0ab6ceffdd.JPG)',
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						height: '300px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'start',
						color: 'white',
						fontSize: '2rem',
						paddingTop: '1rem',
					}}
				>
					<h1 className="text-center text-4xl">
						Auto Parts delivered in record time
					</h1>
				</div>
				<div className="flex flex-col gap-8 p-4">
					<h2 className="text-center text-2xl">It's as easy as...</h2>
					<Stepper activeStep={0} vehicle={{}} />
					<p className="text-sl text-center font-bold">
						Bengine can deliver any part from local stores like Napa and
						AutoZone!
					</p>
					<FindVehicle />
				</div>
			</div>
		</main>
	)
}

export function LoginForm() {
	const loggedInUser = useOptionalUser()

	if (loggedInUser?.id) {
		return (
			<div>
				<b>Welcome to Bengine, {loggedInUser.name}!</b> Use the parts search
				tool at the top of the page to find replacement parts for your race car.
			</div>
		)
	} else {
		return (
			<div className="card w-full max-w-sm shrink-0 bg-base-100 shadow-2xl">
				<form className="card-body">
					<div className="form-control">
						<label className="label">
							<span className="label-text">Email</span>
						</label>
						<input
							type="email"
							placeholder="email"
							className="input input-bordered"
							required
						/>
					</div>
					<div className="form-control">
						<label className="label">
							<span className="label-text">Password</span>
						</label>
						<input
							type="password"
							placeholder="password"
							className="input input-bordered"
							required
						/>
						<label className="label">
							<a href="#" className="link-hover link label-text-alt">
								Forgot password?
							</a>
						</label>
					</div>
					<ul className="mt-5 flex flex-col gap-5 border-b-2 border-t-2 border-border py-3">
						<li>
							<button
								className="btn btn-secondary w-full text-black"
								type="submit"
							>
								Sign Up
							</button>
						</li>
						<li>
							<ProviderConnectionForm
								type="Login"
								providerName={'google'}
								redirectTo={'/'}
							/>
						</li>
					</ul>
				</form>
			</div>
		)
	}
}
